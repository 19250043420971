var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.component
    ? _c(
        "div",
        {
          staticClass: "lesson-banner-component flex-container",
          on: { click: _vm.updateCurrentComponent }
        },
        [
          _c("div", {
            staticClass: "background",
            style: { "background-image": "url(" + _vm.background + ")" }
          }),
          _c("span", { staticClass: "content-component-count" }, [
            _vm._v(_vm._s(_vm.componentCount))
          ]),
          _c("span", { staticClass: "legend centered" }, [
            _vm._v(_vm._s(_vm.bannerName))
          ]),
          _vm.component.setters
            ? _c(
                "section",
                { staticClass: "images-section" },
                [
                  _c("image-upload", {
                    attrs: {
                      component: _vm.component,
                      options: _vm.component.setters[2]
                    }
                  }),
                  _c("image-upload", {
                    attrs: {
                      component: _vm.component,
                      options: _vm.component.setters[3]
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _c("section", { staticClass: "content-section flex-container" }, [
            _c("span", [_vm._v(_vm._s(_vm.title))]),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.essentialQuestion,
                  expression: "essentialQuestion"
                }
              ],
              staticClass: "custom-scrollbar",
              attrs: {
                name: "text",
                placeholder: _vm.$t("essentialQuestionPlaceHolder")
              },
              domProps: { value: _vm.essentialQuestion },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.essentialQuestion = $event.target.value
                }
              }
            })
          ])
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }