<template>
    <div class="lesson-banner-component flex-container" @click="updateCurrentComponent" v-if="component">
        <div :style="{ 'background-image': 'url(' + background + ')' }" class="background"> </div>
        <span class="content-component-count">{{ componentCount }}</span>
        <span class="legend centered">{{bannerName}}</span>
        <section class="images-section" v-if="component.setters">
            <image-upload :component="component"  :options="component.setters[2]"/>
            <image-upload :component="component"  :options="component.setters[3]"/>
        </section>
        <section class="content-section flex-container">
            <span>{{title}}</span>
            <input  name="text" :placeholder="$t('essentialQuestionPlaceHolder')" class="custom-scrollbar"
            v-model="essentialQuestion"/>
        </section>
    </div>
</template>

<script>
import ImageUpload from "@/components/BackOffice/ComponentSetters/ImageUpload.vue";
export default {
  name: "LessonBannerComponent",
  components: {
    "image-upload": ImageUpload,
  },
  methods: {
    updateCurrentComponent() {
      this.$store.commit("updateCurrentBackOfficeComponent", this.component);
    },
    setComponentPropertyValue(value, propertyName = "") {
      this.$store.commit("setBuilderComponentPropertyValue", {
        component: this.component,
        propertyName: propertyName,
        value: value,
      });
    },
  },
  computed: {
    bannerName() {
      let route = this.$route.name;
      if(route === 'LessonBuilder') {
        return this.$t('lessonBanner');
      } else if(route === 'SkillLessonBuilder') {
        return this.$t('skillLessonBanner');
      } else {
        return this.$t('storyBanner');
      }
    },
    title() {
      return this.component.title[this.locale];
    },
    essentialQuestion: {
      get() {
        return this.component.essentialQuestion.question[this.locale];
      },
      set(value) {
        this.$store.commit("setBuilderComponentPropertyValue", {
          component: this.component.essentialQuestion,
          propertyName: "question",
          value: value,
          language: this.locale,
        });
      },
    },
    component() {
      return this.$store.state.lessonBannerComponent;
    },
    setters() {
      return this.component.setters.filter((e) => e.name === "image-upload");
    },
    background() {
      if (!this.component.headerBackground) return;
      return this.getImageUrl(this.component.headerBackgroundName);
    },
  },
  props: {
    componentCount: Number,
  },
};
</script>
<style lang="less" scoped>
.lesson-banner-component {
  position: relative;
  background-color: unset;
  min-height: 189px;
  height: 189px;
  margin-left: 0;

  .legend {
    top: 23px;
    color: rgba(0, 0, 0, 0.38);
  }

  .background {
    position: absolute;
    background-color: #efefef;
    top: 0;
    left: 0;
    opacity: 0.3;
    width: 100%;
    height: 100%;
    max-height: 100%;
    z-index: -1;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .content-section {
    flex-direction: column;
    span {
      height: 28px;
      width: auto;
      color: rgba(0, 0, 0, 0.6);
      font-family: Roboto;
      font-size: 16px;
      letter-spacing: 0.5px;
      line-height: 28px;
      height: 28px;
      margin-left: 0;
      text-align: left;
      max-width: 415px;
    }
  }
  input {
    height: 40px;
    width: 402px;
    background-color: #ffffff;
  }
  section {
    margin-top: 72px;

    input {
      align-self: center;
      border: none;
      color: rgba(0, 0, 0);
      font-family: "Roboto";
      font-size: 12px;
      font-style: italic;
      letter-spacing: 0.4px;
      line-height: 16px;
      padding-left: 10px;
      margin-bottom: 23px;
      resize: none;
      width: 402px;
    }
  }

  .images-section {
    margin-top: 72px;
    .image-upload {
      height: 42px;
      &:nth-child(2) {
        margin-top: 8px;
      }
    }

    :global(.btn) {
      height: 42px;
      width: 237px;
    }
  }
}
</style>



